import RootElementWrapper from './wrap-with-provider'

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = RootElementWrapper

// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
export const onClientEntry = () => {
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

// Every time a user navigates to a new page, fire the FB "PageVview" event
export const onRouteUpdate = ({location}) => {
  if (
    // process.env.GATSBY_IS_UAT === `false` &&
    typeof window.fbq === `function`
  ) {
    window.fbq('track', 'PageView')
  }
}
