import React from 'react'

const CartContext = React.createContext({
  cartId: null,
  cartCount: 0,
  cartState: 'cart',
  address: null,
  addToCart: () => {},
  updateCartCount: () => {},
  setCartState: () => {},
  setAddress: () => {},
})

export default CartContext
