import ky from 'ky'

const kyWrapped = (url, {method = 'get', logout = () => {}, ...content}) => {
  const options = {
    retry: {
      limit: 3,
      statusCodes: [408, 413, 429, 502, 503, 504],
    },
    timeout: 20000,
  }
  switch (method) {
    case 'get':
      return ky
        .get(url, {...content, ...options})
        .json()
        .catch(error => handleException(error, logout))
    case 'post':
      return ky
        .post(url, {...content, ...options})
        .json()
        .catch(error => handleException(error, logout))
    case 'put':
      return ky
        .put(url, {...content, ...options})
        .json()
        .catch(error => handleException(error, logout))
    case 'delete':
      return ky
        .delete(url, {...content, ...options})
        .json()
        .catch(error => handleException(error, logout))
  }
  return
}

// This function will ensure that if the response has any decodable json, it will return it
// Otherwise it will return an empty object
const handleException = async (error, logout) => {
  if (error.response) {
    const errorContent = await error.response.json()
    console.log('Request error decoded: ', errorContent)
    if (
      errorContent.code.includes('invalid_token') ||
      errorContent.code.includes('no_user') ||
      errorContent.code.includes('auth_bad')
    ) {
      logout()
      throw {message: 'You need to be logged in to perform this action'}
    }
    if (errorContent.message) {
      throw errorContent
    }
  } else {
    console.log('Request error cannot be decoded: ', error)
  }
  throw {}
}

export const requestStock = productId => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/restpayment/v1/product-stock/${productId}`
  return kyWrapped(url, {
    method: 'get',
  })
}

export const requestStockList = productIds => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/restpayment/v1/product-list-stock`
  return kyWrapped(url, {
    method: 'post',
    json: {
      products: productIds,
    },
  })
}

export const postReview = (review, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/restpayment/v1/review/${review.id}`
  let headers = {}
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, {
    headers,
    json: review,
    method: 'post',
    logout,
  })
}

export const login = (email, password) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/jwt-auth/v1/token`
  return kyWrapped(url, {
    json: {
      username: email,
      password,
    },
    throwHttpErrors: false,
    method: 'post',
  })
}

export const getOrder = (orderId, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/restpayment/v1/my-orders/${orderId}`
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'get',
    logout,
  })
}

// Function to fetch all orders by through heroku mediator
export const getAllOrders = (token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/restpayment/v1/my-orders`
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'get',
    logout,
  })
}

export const getLatestOrder = (customerId, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/wc/v3/orders?customer=${customerId}&per_page=1&page=1`
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'get',
    logout,
  })
}

export const register = (email, password) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/restpayment/v1/register`
  return kyWrapped(url, {
    json: {
      email,
      password,
    },
    method: 'post',
  })
}

export const getCart = (user, logout = () => {}) => {
  const config = {
    credentials: 'include',
    method: 'get',
    logout,
  }
  // thumb true is added as query instead of json because fetch does not allow a body with a GET request
  let url = `${process.env.GATSBY_WC_HOST}/wp-json/cocart/v1/get-cart?thumb=true`
  if (user) {
    if (
      user.tokenPayload && //oof
      user.tokenPayload.data && //geez
      user.tokenPayload.data.user && //yikes
      user.tokenPayload.data.user.id //so many checks
    ) {
      url = `${url}&id=${user.tokenPayload.data.user.id}`
    }
    if (user.token) {
      config.headers = {
        Authorization: `Bearer ${user.token}`,
      }
    }
  }
  return kyWrapped(url, config)
}

export const getCartTotals = (token = null, logout = () => {}) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/cocart/v1/calculate`
  const config = {
    json: {
      return: true,
    },
    credentials: 'include',
    method: 'post',
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

export const getCartQuantity = (token = null, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/cocart/v1/count-items`
  const config = {
    credentials: 'include',
    method: 'get',
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

export const postCartItem = (
  product,
  quantity = 1,
  token = null,
  logout = () => {},
) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/cocart/v1/add-item`
  const config = {
    json: {
      product_id: product.id || product.wordpress_id,
      quantity,
      refresh_totals: true,
      return_cart: true,
      thumb: true,
    },
    credentials: 'include',
    method: 'post',
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

export const clearCart = (token = null, logout = () => {}) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/cocart/v1/clear`
  const config = {
    credentials: 'include',
    method: 'post',
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

export const deleteCartItem = (product, token = null, logout = () => {}) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/cocart/v1/item`
  const config = {
    json: {
      cart_item_key: product.key,
      refresh_totals: true,
      return_cart: true,
      thumb: true,
    },
    credentials: 'include',
    method: 'delete',
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

export const updateCartItem = (
  product,
  quantity,
  token = null,
  logout = () => {},
) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/cocart/v1/item`
  const config = {
    json: {
      cart_item_key: product.key,
      quantity,
      refresh_totals: true,
      return_cart: true,
      thumb: true,
    },
    credentials: 'include',
    method: 'post',
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

export const getCustomer = (token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/restpayment/v1/my-address`
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'get',
    logout,
  })
}

export const updateCustomer = (address, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/restpayment/v1/my-address`
  // eslint-disable-next-line camelcase
  const {...putBody} = address
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    json: putBody,
    method: 'post',
    logout,
  })
}

export const createOrder = (cartMeta, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/restpayment/v1/order`
  return kyWrapped(url, {
    json: {
      ...cartMeta,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'post',
    logout,
  })
}

export const createPayment = (paymentObject, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/restpayment/v1/payment`
  return kyWrapped(url, {
    json: {
      ...paymentObject,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'post',
    logout,
  })
}

export const requestPassword = email => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/restpayment/v1/autres/init`
  return kyWrapped(url, {
    json: {user_login: email},
    method: 'post',
  })
}

export const submitPassword = (password, token) => {
  const url = `${process.env.GATSBY_WC_HOST}/wp-json/restpayment/v1/autres/hit`
  return kyWrapped(url, {
    json: {
      token,
      password,
    },
    method: 'post',
  })
}
