import React from 'react'

const AuthContext = React.createContext({
  loggedInState: null,
  checkLoggedInState: () => {},
  performLogin: () => {},
  performLogout: () => {},
  performRegister: () => {},
})

export default AuthContext
