import React from 'react'
import CartProvider from './src/components/Context/CartProvider'
import AuthProvider from './src/components/Context/AuthProvider'

const RootElementWrapper = ({element}) => {
  return (
    <AuthProvider>
      <CartProvider>{element}</CartProvider>
    </AuthProvider>
  )
}

export default RootElementWrapper
