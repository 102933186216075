import {useLayoutEffect} from 'react'

// Hook that adds a scroll event listener and lazily runs the function after a scroll
const runLazyHook = (callback = () => {}, msWait = 1000) => {
  const runCallback = () => {
    window.removeEventListener('scroll', runCallback)
    setTimeout(() => {
      callback()
    }, msWait)
  }
  useLayoutEffect(() => {
    window.addEventListener('scroll', runCallback)
    return () => window.removeEventListener('scroll', runCallback)
  }, [])
}

export default runLazyHook
