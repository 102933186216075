/* eslint-disable no-use-before-define */
import React, {useState, useEffect} from 'react'
import {navigate} from 'gatsby'
import AuthContext from './AuthContext'
import {logout, isLoggedIn, userLogin, userRegister} from '../../services/auth'
import {analyticsLogout} from '../../services/GoogleAnalytics'
import {pixelLogout} from '../../services/FacebookPixel'

const isBrowser = () => typeof window !== 'undefined'

const AuthProvider = ({children}) => {
  const [loggedInState, setLoggedInState] = useState(null)

  // Much better login checker!
  const checkLoggedInState = () => {
    console.log('=== Performing login check ===')
    setLoggedInState(isLoggedIn())
  }

  // Just a "register" wrapper
  const performRegister = (
    email,
    password,
    first_name,
    last_name,
    bday,
    setLoading = () => {},
    setApiError = () => {},
    afterCallback = () => {},
  ) => {
    const callback = () => {
      performLogin(email, password, setLoading, setApiError, afterCallback)
    }
    userRegister(
      email,
      password,
      first_name,
      last_name,
      bday,
      setLoading,
      setApiError,
      callback,
    )
  }

  // Just a "userLogin" wrapper that also updates the global state
  const performLogin = (
    email,
    password,
    setLoading = () => {},
    setApiError = () => {},
    afterCallback = () => {},
  ) => {
    const callback = () => {
      afterCallback()
    }
    userLogin(
      email,
      password,
      setLoading,
      setApiError,
      setLoggedInState,
      callback,
    )
  }

  // Perform a logout, update the global logged in state and navigate away
  const performLogout = (navigateLink = '/') => {
    logout()
    setLoggedInState(false)
    analyticsLogout()
    pixelLogout()
    if (navigateLink !== null) {
      if (typeof navigateLink === 'string') {
        navigate(navigateLink)
      } else {
        navigate('/')
      }
    }
  }

  // on every render... maybe too much?
  useEffect(checkLoggedInState)

  return (
    <AuthContext.Provider
      value={{
        loggedInState,
        checkLoggedInState,
        performRegister,
        performLogin,
        performLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
