/* eslint-disable no-use-before-define */
import React, {useState, useEffect, useLayoutEffect, useContext} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import CartContext from './CartContext'
import AuthContext from '../Context/AuthContext'
import {getUser} from '../../services/auth'
import {
  getCart,
  postCartItem,
  deleteCartItem,
  updateCartItem,
  getCartTotals,
  clearCart,
  getOrder,
} from '../../utils/WordPress'
import runLazyHook from '../../utils/run-lazy-hook'

const CartProvider = ({children}) => {
  const [cartId, setCartId] = useState(null)
  const [cartCount, setCartCount] = useState(null)
  const [cartContents, setCartContents] = useState(null)
  const [cartMeta, setCartMeta] = useState(null)
  const [cartReady, setCartReady] = useState(true)
  const [cartState, setCartState] = useState('cart')
  const [address, setAddress] = useState('cart')
  const {performLogout} = useContext(AuthContext)

  // These are product ID's for shipping and promo items. They cannot be populated here
  // because static GraphQL queries cannot be made in a React Context. They get populated
  // when the customer goes to the /cart page
  const [shipId, setShipId] = useState(null)
  const [specialProducts, setSpecialProducts] = useState(null)

  const allProductsQueryData = useStaticQuery(graphql`
    {
      allWcProducts(filter: {catalog_visibility: {eq: "visible"}}) {
        nodes {
          wordpress_id
          categories {
            name
          }
        }
      }
    }
  `).allWcProducts.nodes

  const updateCartCount = newCartCount => {
    if (newCartCount !== cartCount) {
      // When we have orderid specified in the URL, don't mess with cart states
      if (window && !window.location.search.includes('orderid')) {
        setCartState('cart')
      }
      setCartCount(newCartCount)
    }
  }

  const addToCart = async (productId, quantity = 1) => {
    const user = getUser()
    const cartContentsResponse = await postCartItem(
      productId,
      quantity,
      user.token,
      performLogout,
    )
    setCartContentsSorted(Object.values(cartContentsResponse))

    return cartContentsResponse
  }

  const removeFromCart = async (product, quantity = 1) => {
    const user = getUser()
    return deleteCartItem(product, user.token, performLogout).then(data => {
      setCartContentsSorted(Object.values(data))
    })
  }

  const updateInCart = async (product, quantity) => {
    const user = getUser()
    return updateCartItem(product, quantity, user.token, performLogout).then(
      data => {
        setCartContentsSorted(Object.values(data))
      },
    )
  }

  // Get all cart data
  // Get items in Cart
  const getCartContents = async () => {
    setCartReady(false)
    const user = getUser()
    getCart(user, performLogout) // Get items in cart
      .then(data => {
        setCartContentsSorted(Object.values(data))
      })
      .catch(e => {
        console.log(e)
      })
    getCartMeta() // Get cart meta info (quantities and totals)
  }

  // Get cart meta data, such as totals
  const getCartMeta = async () => {
    const user = getUser()
    getCartTotals(user.token, performLogout) // Get cart meta info (quantities and totals)
      .then(data => {
        setCartMeta(data)
      })
      .catch(e => {
        console.log(e)
      })
  }

  // WooCommerce throws away the guest cart when a user logs in. So how do we keep the cart
  // when a user registers during checkout? We clear his new cart and add the old items one by one.
  // This is the worst thing ever but at least it works.
  const forceUseCurrentCart = async () => {
    console.log('Entering forceUseCurrentCart()')
    const user = getUser()
    try {
      await clearCart(user.token, performLogout)
      asyncForEach(cartContents, async (it, i) => {
        const prod = {
          id: it.variation_id || it.product_id,
        }
        console.log('Replace old cart item: ', prod)
        await postCartItem(prod, it.quantity, user.token, performLogout)
        if (i === cartContents.length - 1) {
          // on last item, fetch cart
          getCartContents()
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  const verifyCart = () => {
    const user = getUser()
    setCartReady(false)

    // Ensure promo items are the correct amount
    if (specialProducts) {
      const promoProds = cartContents.filter(
        i => i.product_id === specialProducts.promoProduct.wordpress_id,
      )
      const promoProdsAmount = promoProds.reduce((tot, num) => {
        return tot + num.quantity
      }, 0)
      const mainProds = cartContents.filter(i =>
        specialProducts.mattressProducts.edges.some(
          ed => ed.node.wordpress_id === i.product_id,
        ),
      )
      const mainProdsAmount = mainProds.reduce((tot, num) => {
        return tot + num.quantity
      }, 0)

      if (promoProdsAmount !== mainProdsAmount) {
        if (promoProdsAmount > 0) {
          if (mainProdsAmount > 0) {
            updateInCart(promoProds[0], mainProdsAmount, user.token)
              .then(() => setCartReady(true))
              .catch(() => setCartReady(true))
          } else {
            removeFromCart(promoProds[0], user.token)
              .then(() => setCartReady(true))
              .catch(() => setCartReady(true))
          }
        } else {
          addToCart(specialProducts.promoProduct, mainProdsAmount, user.token)
            .then(() => setCartReady(true))
            .catch(() => setCartReady(true))
        }
      } else {
        setCartReady(true)
      }
    }
  }

  // Fetch order by Order ID and handle errors properly
  const getOrderById = async orderId => {
    const {token} = getUser()

    // Verify the order ID is valid and verify that the order status is "unpaid"
    try {
      const orderData = await getOrder(orderId, token, performLogout)
      console.log('Fetched Order: ', orderData)

      // Setting order data will render the PeachPay component which will trigger the fetch of payment data
      if (orderData.id) {
        return orderData
      } else {
        return {
          error: 'An error occured while fetching your order, order invalid.',
        }
      }
    } catch (e) {
      console.log('Failed to get order by ID')
      if (e && e.errors && e.errors[0] && e.errors[0].detail) {
        console.log(e.errors[0].detail)
        return {error: e.errors[0].detail}
      } else if (e.message) {
        return {error: e.message}
      } else {
        return {
          error: 'An error occured while fetching your order.',
        }
      }
    }
  }

  useEffect(() => {
    if (cartContents) {
      // Ensure cart-count for icon is correct
      const total = cartContents.reduce((tot, num) => {
        return tot + num.quantity
      }, 0)
      updateCartCount(total)
      if (isOnCartPage()) {
        // Ensure promo items etc are correctly accounted for
        verifyCart()
      } else {
        setCartReady(true)
      }
    }
  }, [cartContents])

  // When cart context loads, initialise variables and counts very lazily
  runLazyHook(() => {
    if (!isOnCartPage()) {
      // No cart data available
      getCartContents()
    }
  }, 100)

  // // Sort items before assigning state
  const setCartContentsSorted = data => {
    setCartContents(
      data
        .sort((a, b) => b.line_total - a.line_total)
        .map(cartItem => {
          const allProdCatString = 'All Products'
          cartItem.categories = [{name: allProdCatString}]
          for (const productsQueryData of allProductsQueryData) {
            if (productsQueryData.wordpress_id === cartItem.product_id) {
              cartItem.categories = productsQueryData.categories
              break
            }
          }
          return cartItem
        }),
    )
  }

  // This function tries to check if the user is on cart page
  // It helps us to reduce numerous cart-related calls when they are not necessary
  const isOnCartPage = () => {
    if (
      typeof window !== 'undefined' &&
      window.location.pathname &&
      !window.location.pathname.startsWith('/cart')
    ) {
      return false
    }

    return true
  }

  return (
    <CartContext.Provider
      value={{
        cartId,
        cartCount,
        cartState,
        setCartState,
        addToCart,
        removeFromCart,
        address,
        setAddress,
        setShipId,
        setSpecialProducts,
        cartContents,
        cartMeta,
        cartReady,
        getCartContents,
        getCartMeta,
        forceUseCurrentCart,
        isOnCartPage,
        allProductsQueryData,
        getOrderById,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}
