/* eslint-disable radix */
const isBrowser = () => typeof window !== 'undefined'

// FACEBOOK PIXEL --- Select Product Event
export const pixelSelect = item => {
  try {
    if (isBrowser() && window.fbq != null) {
      const payload = {
        content_name: item.name,
        content_category: item.categories,
        value: parseInt(item.price),
        currency: 'NZD',
      }
      if (process.env.GATSBY_PIXEL_DEBUG == 'true')
        console.log(
          'pixel event ViewProduct [trackCustom]: payload = ',
          payload,
        )
      window.fbq('trackCustom', 'ViewProduct', payload)
    }
  } catch {
    if (process.env.GATSBY_PIXEL_DEBUG == 'true')
      console.log(
        'pixel event: Could not run Facebook Pixel function "Select Product Event"',
      )
  }
}

// FACEBOOK PIXEL --- Add Items To Cart Event
export const pixelAddToCart = (items, cartTotal) => {
  try {
    if (isBrowser() && window.fbq != null) {
      for (const item of items) {
        const payload = {
          content_name: item.name,
          value: cartTotal,
          currency: 'NZD',
        }
        if (process.env.GATSBY_PIXEL_DEBUG == 'true')
          console.log('pixel event AddToCart: payload = ', payload)
        if (isBrowser() && window.fbq != null) {
          window.fbq('track', 'AddToCart', payload)
        }
      }
    }
  } catch {
    if (process.env.GATSBY_PIXEL_DEBUG == 'true')
      console.log(
        'Could not run Facebook Pixel function "Add Items To Cart Event".',
      )
  }
}

// FACEBOOK PIXEL --- Go to Cart
export const pixelGoToCart = data => {
  try {
    if (isBrowser() && window.fbq != null) {
      const payload = {
        num_items: data.length,
        value: data.reduce((tot, curr) => tot + curr.line_total, 0),
        currency: 'NZD',
      }
      if (process.env.GATSBY_PIXEL_DEBUG == 'true')
        console.log('pixel event InitiateCheckout: payload = ', payload)
      window.fbq('track', 'InitiateCheckout', payload)
    }
  } catch {
    if (process.env.GATSBY_PIXEL_DEBUG == 'true')
      console.log(
        'pixel event: Could not run Facebook Pixel function "Go To Cart".',
      )
  }
}

// FACEBOOK PIXEL --- Purchase Event
export const pixelPurchase = (paymentData, items) => {
  try {
    if (isBrowser() && window.fbq != null) {
      const payload = {
        value: paymentData.total,
        currency: 'NZD',
      }
      if (process.env.GATSBY_PIXEL_DEBUG == 'true')
        console.log('pixel event Purchase: payload = ', payload)
      window.fbq('track', 'Purchase', payload)
    }
  } catch {
    if (process.env.GATSBY_PIXEL_DEBUG == 'true')
      console.log(
        'pixel event: Could not run Facebook Pixel function "Purchase Event".',
      )
  }
}

// FACEBOOK PIXEL --- Complete Registration event
export const pixelCompleteRegistration = () => {
  try {
    if (isBrowser() && window.fbq != null) {
      const payload = {
        currency: 'NZD',
        value: 0,
      }
      if (process.env.GATSBY_PIXEL_DEBUG == 'true')
        console.log('pixel event CompleteRegistration: payload = ', payload)
      window.fbq('track', 'CompleteRegistration', payload)
    }
  } catch {
    if (process.env.GATSBY_PIXEL_DEBUG == 'true')
      console.log(
        'Could not run Facebook Pixel function "Complete Registration Event".',
      )
  }
}

// FACEBOOK PIXEL --- Login Event
export const pixelLogin = () => {
  try {
    if (isBrowser() && window.fbq != null) {
      if (process.env.GATSBY_PIXEL_DEBUG == 'true')
        console.log('pixel event Login [trackCustom]: no payload')
      window.fbq('trackCustom', 'Login')
    }
  } catch {
    if (process.env.GATSBY_PIXEL_DEBUG == 'true')
      console.log(
        'pixel event: Could not run Facebook Pixel function "Login Event".',
      )
  }
}

// FACEBOOK PIXEL --- Logout Event
export const pixelLogout = () => {
  try {
    if (isBrowser() && window.fbq != null) {
      if (process.env.GATSBY_PIXEL_DEBUG == 'true')
        console.log('pixel event Logout [trackCustom]: no payload')
      window.fbq('trackCustom', 'Logout')
    }
  } catch {
    if (process.env.GATSBY_PIXEL_DEBUG == 'true')
      console.log(
        'pixel event: Could not run Facebook Pixel function "Logout Event".',
      )
  }
}

// FACEBOOK PIXEL --- Update Account Event
export const pixelUpdateAccount = () => {
  try {
    if (isBrowser() && window.fbq != null) {
      if (process.env.GATSBY_PIXEL_DEBUG == 'true')
        console.log('pixel event UpdateAccount [trackCustom]: no payload')
      window.fbq('trackCustom', 'UpdateAccount')
    }
  } catch {
    if (process.env.GATSBY_PIXEL_DEBUG == 'true')
      console.log(
        'pixel event: Could not run Facebook Pixel function "Update Account Event".',
      )
  }
}

// FACEBOOK PIXEL --- Contact Us event
export const pixelContactUs = () => {
  try {
    if (isBrowser() && window.fbq != null) {
      if (process.env.GATSBY_PIXEL_DEBUG == 'true')
        console.log('pixel event Contact: no payload')
      window.fbq('track', 'Contact')
    }
  } catch {
    if (process.env.GATSBY_PIXEL_DEBUG == 'true')
      console.log(
        'pixel event: Could not run Facebook Pixel function "Contact Us Event".',
      )
  }
}
